import styled from 'styled-components';

export const Holder = styled.div`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/texture-slate.png)
		0 0 repeat #262524;
`;

export const Container = styled.div`
	width: 100%;
	display: block;
	padding: 30px;
	width: auto;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	color: ${({ theme }) => theme.colors.neutral.white};

	@media (min-width: 568px) {
		max-width: 540px;
	}

	@media (min-width: 768px) {
		max-width: 700px;
	}

	@media (min-width: 1200px) {
		max-width: 850px;
	}
`;

export const Title = styled.div`
	font-size: 1.63rem;
	font-family: introRust, sans-serif;
	font-weight: 500;

	line-height: 1.2;
	position: relative;
	min-height: 1px;
	width: 100%;
	padding-left: 7.5px;
	padding-right: 7.5px;
	margin-bottom: 15px;

	@media (min-width: 568px) {
		font-size: 1.77rem;
	}

	@media (min-width: 768px) {
		font-size: 1.9rem;
	}

	@media (min-width: 992px) {
		font-size: 2.07rem;
	}

	@media (min-width: 1200px) {
		font-size: 2.2rem;
	}

	@media (min-width: 1500px) {
		font-size: 2.35rem;
	}

	@media (min-width: 1800px) {
		font-size: 2.52rem;
	}
`;

export const ImageDiv = styled.div`
	display: block;
	width: 100%;
	height: auto;

	img {
		vertical-align: middle;
		max-width: 100%;
	}
`;

export const LinksContainer = styled.div`
	display: flex;
	justify-content: space-around;
	gap: 20px;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		flex-wrap: nowrap;
	}
`;

export const LinkDiv = styled.div`
	width: 100%;
	white-space: nowrap;
`;
