import { OneTrustModal } from '@maverick/cms';
import {
	Calendar,
	Category,
	Menu,
	NavigationProps,
	NavigationVariant,
	OptionGroup,
	Product,
	Restaurant,
} from '@maverick/entity';
import { CmsFooter, FooterMenuItemProps, OutbackHeader, SkipLink } from '@maverick/ui';
import { DictionaryPhrases } from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
import { Config } from 'src/Config';
import { HomeData } from 'src/services/HomeData.service';
import { MenuLink } from 'components/_shared/MenuLink';
import { SocialMediaIcons } from 'components/_shared/SocialMediaIcons/SocialMediaIcons';
import { Location } from 'components/cms/Location';
import Link from 'next/link';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthenticationHandler } from '@maverick/authentication';
import { HeaderInteraction } from 'components/cms/HeaderInteraction';
import styled from 'styled-components';

const cmsUrl = Config.Env.IsLocal ? 'http://localhost:3000' : Config.Urls.Cms;
const brand = Config.Brand;
const env = Config.Env.Is;
export const REVALIDATE_DELAY = 1;

const api = axios.create({
	baseURL: cmsUrl,
	headers: {
		host: cmsUrl.replace(/^[^:]+:\/\//, ''),
		accept: '*/*',
		'user-agent': `Maverick-${brand}-CMS/v4 (${env}; ${cmsUrl})`,
	},
});

export interface BrowsableMenuPageProps {
	params: {
		restaurantSlug?: string;
		categoryId?: string;
		productId?: string;
		restaurantMenu?: Menu | null;
		restaurantInfo?: string | null;
		productModifiers?: Array<OptionGroup> | null;
		selectedProduct?: Product | null;
		selectedCategory?: Category | null;
		items: NavigationProps;
		homeData: HomeData | null;
		dictionary: DictionaryPhrases | null;
		auth0Config: typeof Config.Auth0;
	};
}

export const getEmptyNavigationItems = () => {
	const navigationItems: NavigationProps = {
		mainItems: [],
		hamburgerItems: [],
		featuredItems: [],
		unfeaturedItems: [],
	};
	return navigationItems;
};

export const retrieveRestaurantBySlug = async (params: any) => {
	try {
		if (params.restaurantSlug === `restaurant`) {
			console.warn(`[retrieveRestaurantBySlug] params.restaurantSlug is 'restaurant'`);
			return null;
		}

		const { status, data } = await api.get(`api/olo/v1.1/restaurants/byslug/${params.restaurantSlug}`, {
			headers: { 'x-mav-cache': 3_600 },
		});
		if (status !== 200 || !data) {
			console.warn(`[retrieveRestaurantBySlug] Status: ${status}`);
			return null;
		}

		return Restaurant.Make(data);
	} catch (err) {
		console.error('Error retrieving restaurant by slug', err);
		return null;
	}
};

export const retrieveRestaurantMenu = async (restaurant: Restaurant | null) => {
	if (!restaurant) {
		console.warn(`[retrieveRestaurantMenu] Restaurant is null`);
		return null;
	}

	try {
		const { status, data } = await api.get(`api/olo/v1.1/restaurants/${restaurant.id}/menu`, {
			headers: { 'x-mav-cache': 3_600 },
		});
		if (status !== 200 || !data) {
			console.warn(`[retrieveRestaurantMenu] Status: ${status}`);
			return null;
		}

		return data as Menu;
	} catch (err) {
		console.error(`[retrieveRestaurantMenu] Error: ${err}`);
		return null;
	}
};

export const retrieveRestaurantWithCalendar = async (restaurant: Restaurant | null) => {
	if (!restaurant) {
		console.warn(`[retrieveRestaurantWithCalendar] Restaurant is null`);
		return null;
	}

	try {
		const now = new Date();
		const today = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(
			now.getDate()
		).padStart(2, '0')}`;

		const { status, data } = await api.get(
			`api/olo/v1.1/restaurants/${restaurant.id}/calendars?from=${today}&to=${today}`
		);
		if (status !== 200 || !data) {
			console.warn(`[retrieveRestaurantWithCalendar] Status: ${status}`);
			return null;
		}

		const businessCalendar = data.calendar.find((c: any) => c.type === 'business');
		const curbsideCalendar = data.calendar.find((c: any) => c.type === 'curbsidepickup');
		const deliveryCalendar = data.calendar.find((c: any) => c.type === 'delivery');

		const result = {
			businessCalendar: !!businessCalendar ? Calendar.Make(businessCalendar) : null,
			curbsideCalendar: !!curbsideCalendar ? Calendar.Make(curbsideCalendar) : null,
			deliveryCalendar: !!deliveryCalendar ? Calendar.Make(deliveryCalendar) : null,
		};

		const calendar = () => {
			const calendarArray = [result.businessCalendar, result.curbsideCalendar, result.deliveryCalendar];
			const resultArray = calendarArray.filter((c) => !!c);
			return resultArray.length > 0 ? resultArray : undefined;
		};

		const newRestaurant = { ...restaurant, calendars: calendar() };
		return newRestaurant as Restaurant;
	} catch (error) {
		console.error(`[retrieveRestaurantWithCalendar] Error: ${error}`);
		return null;
	}
};

export const retrieveProductModifiers = async (params: any) => {
	if (!params.productId) {
		console.warn(`[retrieveProductModifiers] params.productId is null`);
		return null;
	}

	try {
		const { status, data } = await api.get(`api/olo/v1.1/products/${params.productId}/modifiers`, {
			headers: { 'x-mav-cache': 3_600 },
		});
		if (status !== 200 || !data) {
			console.warn(`[retrieveProductModifiers] Status: ${status}`);
			return null;
		}

		return data.optiongroups as Array<OptionGroup>;
	} catch (err) {
		console.error(`[retrieveProductModifiers] Error: ${err}`);
		return null;
	}
};

export const getHeader = (items: NavigationProps, homeData: HomeData, auth0Config: typeof Config.Auth0) => {
	return (
		<>
			<SkipLink text={'SKIP TO MAIN CONTENT'} link={'main-content'} />
			<SkipLink text={'SKIP TO FOOTER'} link={'footer'} />
			<OutbackHeader
				linkComponent={Link}
				items={items}
				img={<img src={homeData?.fields.Logo?.src} alt={homeData?.fields.Logo?.alt} />}
				imgMobile={<img src={homeData?.fields.LogoMobile?.src} alt={homeData?.fields.LogoMobile?.alt} />}
				extra={
					<Auth0Provider
						domain={auth0Config.domain}
						clientId={auth0Config.clientId}
						redirectUri={auth0Config.redirectUriCms}
						audience={auth0Config.audience}
						useRefreshTokens
						cacheLocation='localstorage'
						brand={Config.Brand}
					>
						<AuthenticationHandler />
						<Location />
						<HeaderInteraction />
					</Auth0Provider>
				}
			/>
		</>
	);
};

export const getFooter = (items: NavigationProps, homeData: HomeData) => {
	const menuItems: FooterMenuItemProps[] | undefined = items.featuredItems?.map((item) => {
		return {
			variant: NavigationVariant.Featured,
			link: MenuLink(item.url, item.label, !item.newTab),
		};
	});
	items.unfeaturedItems?.forEach((item) => {
		menuItems?.push({
			variant: NavigationVariant.Unfeatured,
			link: MenuLink(item.url, item.label, !item.newTab),
		});
	});
	const SROnlyTitle = styled.h2`
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border-width: 0;
	`;
	return (
		<>
			<SROnlyTitle id='footer-navigation'>Footer Navigation</SROnlyTitle>
			<CmsFooter
				footerMenuItems={menuItems}
				socialMediaIcons={SocialMediaIcons(
					homeData?.fields?.FacebookUrl ?? '',
					homeData?.fields?.InstagramUrl ?? '',
					homeData?.fields?.TwitterUrl ?? ''
				)}
				oneTrustModal={<OneTrustModal />}
			/>
		</>
	);
};
