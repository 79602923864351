import { ButtonCMS } from '@maverick/cms';
import * as Styled from './MustBeAMisSteak.styled';

export const MustBeAMisSteak = () => {
	return (
		<Styled.Holder>
			<Styled.Container>
				<Styled.Title>This must be a Mis-Steak!</Styled.Title>
				<Styled.ImageDiv data-testid='imageDiv'>
					<img
						src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/shared/sirloin-360-1.webp'
						alt='Steak Image'
					/>
				</Styled.ImageDiv>
				<Styled.LinksContainer>
					<Styled.LinkDiv data-testid='homePage'>
						<ButtonCMS
							variant='secondary'
							button={{ label: 'Go To Homepage', href: '/', target: '_self' }}
						/>
					</Styled.LinkDiv>
					<Styled.LinkDiv data-testid='contact'>
						<ButtonCMS
							variant='secondary'
							button={{ label: 'Contact Us', href: '/contact-us', target: '_self' }}
						/>
					</Styled.LinkDiv>
				</Styled.LinksContainer>
			</Styled.Container>
		</Styled.Holder>
	);
};
