import { Icon } from '@maverick/ui';
import { IconInstagram } from '@maverick/icons/dist/IconInstagram';
import { IconFacebook } from '@maverick/icons/dist/IconFacebook';
import { IconTwitter } from '@maverick/icons/dist/IconTwitter';

export const InstagramIcon: JSX.Element = <Icon icon={IconInstagram} customSize color={'#121211'} />;

export const FacebookIcon: JSX.Element = <Icon icon={IconFacebook} customSize color={'#121211'} />;

export const TwitterIcon: JSX.Element = <Icon icon={IconTwitter} customSize color={'#121211'} />;

export function SocialMediaIcons(instagramUrl: string, facebookUrl: string, twitterUrl: string) {
	return [
		{
			link: instagramUrl,
			icon: InstagramIcon,
			ariaLabel: 'Outback Steakhouse Instagram',
			label: 'follow us on instagram',
		},
		{
			link: facebookUrl,
			icon: FacebookIcon,
			ariaLabel: 'Outback Steakhouse Facebook',
			label: 'follow us on facebook',
		},
		{
			link: twitterUrl,
			icon: TwitterIcon,
			ariaLabel: 'Outback Steakhouse X',
			label: 'follow us on X',
		},
	];
}
